<template>
  <div class="about">
    <div class="banner">
      <!-- <img src="@/assets/partnership/incabin.png" alt="" /> -->
      <ImageWithPlaceholder src="/image/about/aboutus.png" alt="Loaded Image" />
    </div>
    <div class="about-box">
      <div class="content-wrapper">
        <h1-title :mb="35"> About Us </h1-title>
        <p class="about-text">
          ArcSoft is a leading algorithm and software solutions provider in the
          computer vision industry, serving OEM customers around the world with
          cutting edge technologies, enabling advanced AI vision products in
          digital cameras, smartphones, smart cars, smart glasses, and other
          digital devices.<br />
          Since its founding 30 years ago, ArcSoft has been focusing on digital
          image processing, computational photography, computer vision, and AI
          technologies. During the past 30 years, ArcSoft has helped smartphone
          makers and digital electronics manufacturers to ship billions of
          products embedded with ArcSoft products, improving the image capture
          processes and picture/ video qualities with exciting new features and
          fulfilling user experiences.<br />
          ArcSoft has gained remarkable reputation and market share during the
          recent revolutionary intelligent driving movements in the automotive
          industry, helping automotive OEMs shipping tens of car models with
          DMS, OMS, and ADAS features that not only satisfy regulatory
          requirements such as GSR I & II, but also bring new innovative
          software applications in the cabin for simpler, better, and more
          convenient application interactions. We are proud to help automotive
          OEMs and Tier 1, Tier 2 partners to create a safer and more exciting
          driving and riding experiences in the future.<br />
          ArcSoft has successfully built up a top R&D team and intellectual
          properties with hundreds of scientists and engineers specialized in
          all aspects of digital photography, computer vision, and automotive
          sensing and perceptions, enabling our customers to offer innovative
          features on their existing hardware platform for value
          differentiations and early time-to-the-market opportunities.<br />
          Moving forward, ArcSoft will continue the innovative efforts in the
          visual perception and computational photography software fields,
          helping our OEM customers and partners to offer more competitive and
          higher value products to their end users.
        </p>
        <div class="img">
          <img src="/image/about/map.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder.vue";
export default {
  name: "About",
  metaInfo: {
    title: "About us - ArcSoft",
    meta: [
      {
        name: "description",
        content: "description",
      },
      {
        name: "keywords",
        content: "About", // 关键词
      },
    ],
  },
  components: { ImageWithPlaceholder },
};
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
}

img {
  width: 100%;
}

.about-box {
  margin: 80px 0 50px 0;
  .about-text {
    margin-bottom: 24px;
  }
}

.content-wrapper {
  width: 960px;
  margin: 0 auto;
}
</style>
